import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { MappedImage } from '../Image'
import parse from 'html-react-parser'

const liveChatPopout = (event, link, orgId) => {
  const liveChatUrl = new URL(link)
  liveChatUrl.searchParams.append('orgId', orgId)
  event.preventDefault()
  window.open(
    liveChatUrl.toString(),
    '_blank',
    'height=600,width=500,location=0,menubar=0,toolbar=0'
  )
}

export const Footer = ({
  footerNavOne,
  footerNavTwo,
  footerText,
  footerLogo,
  copyrightText,
  socialItems,
  payerFooter,
  orgId,
}) => {
  return (
    <footer className="footer">
      <Container fluid className="p-4 wellvolution-top-footer">
        {socialItems &&
          socialItems.map(item => {
            return (
              <div key={item.navigationTitle} className="mw row">
                {item.socialIcon && (
                  <Col sm={12} md={2} className="social-img-outer">
                    <MappedImage
                      style={{ maxWidth: '130px' }}
                      image={item.socialIcon}
                    />
                  </Col>
                )}
                <Col sm={12} md={10} className="social-item-outer">
                  <div className="social-item-inner">{item.navigationLink}</div>
                </Col>
              </div>
            )
          })}
      </Container>
      <Container
        fluid
        className="well-footer-outer py-4"
        style={{ backgroundColor: '#093b5c' }}
      >
        <Row className="footer-inner mx-auto" style={{ maxWidth: '1200px' }}>
          <Col sm={12} md={4} className="my-3">
            <div className="h-100 w-100 d-flex flex-column justify-content-center">
              <div className="well-footer-logo">
                {footerLogo && (
                  <MappedImage
                    image={footerLogo}
                    alt={`${footerLogo?.title}`}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col sm={12} md={8} className="my-3">
            <nav className="nav justify-content-center">
              {footerNavOne &&
                footerNavOne.map(item => {
                  return (
                    <div
                      className="nav-link-wrapper"
                      key={item.navigationTitle}
                    >
                      {item.navigationTitle === 'Live chat' ? (
                        <a
                          onClick={event =>
                            liveChatPopout(
                              event,
                              item.navigationLink,
                              orgId || 'BSC'
                            )
                          }
                          className="nav-link text-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.navigationTitle}
                        </a>
                      ) : (
                        <a
                          href={item.navigationLink}
                          className="nav-link text-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.navigationTitle}
                        </a>
                      )}
                    </div>
                  )
                })}
            </nav>
          </Col>
        </Row>
        <Row
          className="well-footer-inner mx-auto text-white"
          id="well-footer-text"
          style={{ maxWidth: '1200px' }}
        >
          {payerFooter && parse(payerFooter.payerFooter)}
          {footerText && parse(footerText.footerText)}
          {copyrightText && parse(copyrightText.copyrightText)}
          {footerNavTwo &&
            footerNavTwo.map(item => {
              return (
                <Col key={item.navigationTitle}>
                  <a
                    href={item.navigationLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.navigationTitle}
                  </a>
                </Col>
              )
            })}
        </Row>
      </Container>
    </footer>
  )
}

export const mapFooterProps = props => props
